<template>
    <v-tabs show-arrows>
        <slot name="firstTab" />
        <template v-for="(tab, i) in tabs">
            <v-tab :key="tab.text">
                {{ tab.text }}
            </v-tab>
            <v-tab-item :key="i">
                <component
                    :is="tab.table"
                    :filter="getTabFilter(tab.value)"
                    :refresh-key="refreshKey" />
            </v-tab-item>
        </template>
        <slot name="lastTab" />
    </v-tabs>
</template>

<script>
export default {
    props: {
        tabs: {
            type: Object,
            default: null,
        },
        getTabFilter: {
            type: Function,
            default: null,
        },
        refreshKey: {
            type: String,
            default: null,
        },
    },
};
</script>
